body {
  .green-copy {
    color: $color-cl-green-2;
  }
  #gnav_signin,
  #main {
    .signin_container {
      .inner-block {
        .loyalty__block {
          @include swap_direction(margin, 0 0 17px 65px);
          width: 53%;
          float: #{$rdirection};
          background: url('/media/export/cms/Clinique_Loyalty_NA/5_19_CL_enrollgnav_register_v3.png') #{$ldirection} bottom;
          background-repeat: no-repeat;
          padding-#{$ldirection}: 52px;
          background-size: contain;
          position: relative;
          .loyalty__make {
            font-family: $base-bold-font-family;
            color: $color-black;
            font-size: 21px;
          }
          .loyalty__our {
            @include swap_direction(margin, 20px 0 6px 0);
            font-size: 24px;
            line-height: 30px;
          }
          .loyalty__text {
            font-size: 19px;
            line-height: 1.2;
            color: $color-black;
          }
          .loyalty__subdivision {
            &__left_block {
              display: inline-block;
              width: 47%;
              float: #{$ldirection};
              ul {
                @include swap_direction(padding, 15px 0 15px 15px);
                list-style-type: disc;
                font-size: 13px;
                color: $color-black;
                li {
                  line-height: 2;
                }
              }
            }
            &__right_block {
              display: inline-block;
              position: relative;
              width: 100%;
              .loyal_image {
                position: absolute;
                top: -245px;
                width: 50%;
                right: -8%;
                max-width: 500px;
                @media #{$xlarge-up} {
                  width: 100%;
                  right: -60%;
                  max-width: 625px;
                }
              }
            }
          }
        }
        .login-email {
          width: 39%;
          float: #{$ldirection};
          form {
            .form-item,
            .row {
              &.privacy-form-container,
              &.email_promotions,
              &.loyalty {
                input[type='checkbox'] {
                  @include swap_direction(margin, 4px 0 0 0);
                  vertical-align: top;
                }
              }
            }
          }
        }
        .privacy-form-container.row {
          .register-terms,
          .loyalty-terms {
            width: 80%;
          }
          .loyalty__rewards {
            font-weight: bold;
          }
          .loyalty__offer_email {
            width: auto;
            padding-#{$ldirection}: 16px;
            font-size: 11px;
          }
        }
        .email_promotions.row .register-email-offers {
          width: 80%;
        }
      }
      form .row.privacy-form-container.loyalty {
        display: none;
      }
      .pane {
        @include swap_direction(padding, 25px 0 20px 0);
      }
    }
    .topnav-pane {
      .fourcol,
      .twocol {
        margin-top: 40px;
      }
      .twocol {
        .avatar {
          position: absolute;
          top: 0;
          #{$rdirection}: 250px;
          .thumb {
            width: 60px;
          }
          .bio {
            float: #{$rdirection};
          }
          .row {
            .twocol {
              position: relative;
              top: 0;
              padding: 0;
              margin: 5px 0 0 0;
              width: 100%;
              .signout {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
  #main {
    .signin_container {
      .inner-block {
        .loyalty__block {
          .loyalty__logo {
            margin-#{$ldirection}: 4%;
          }
          &__left,
          &__right {
            display: inline-block;
          }
          &__left {
            width: 66%;
            margin-#{$ldirection}: 4%;
            float: #{$ldirection};
            @media #{$xlarge-up} {
              width: 56%;
            }
            .row {
              margin: 10px 0 0 0;
            }
          }
          &__right {
            width: 40%;
            #{$ldirection}: 65%;
            top: 10px;
            #{$rdirection}: 0;
            z-index: -1;
            position: inherit;
            @media #{$medium-only} {
              width: 44%;
              top: 100px;
            }
            @media #{$large-med-up} {
              width: 50%;
              #{$ldirection}: 50%;
            }
            @media #{$xlarge-up} {
              width: 60%;
              #{$ldirection}: 55%;
            }
            @media #{$xlargexxl-up} {
              width: 90%;
              #{$ldirection}: 60%;
            }
            .loyal_image {
              width: 100%;
              max-width: 550px;
              float: #{$rdirection};
              @media #{$xlarge-up} {
                position: relative;
              }
              @media #{$xlargexxl-up} {
                max-width: 625px;
              }
            }
          }
        }
      }
    }
  }
  #gnav_signin {
    .signin_container {
      .row {
        overflow: visible;
      }
    }
  }
  #navigation {
    .menu-item-block.active {
      & > .outer-wrap {
        display: unset;
      }
    }
  }
  .account-page {
    #sms_verification {
      fieldset {
        margin: 0 2px 10px;
        border: none;
        p {
          margin-bottom: 6px;
        }
      }
    }
  }
  &#my_points {
    #loyalty__panel__points .account-loyalty {
      .level.current-level-0 {
        .star-points-benifits {
          display: none;
        }
      }
    }
  }
  &#past_purchases {
    .past-purchases .order-info {
      .shipments-list .product {
        .my-account-sku[redemption_sku~='1'] {
          .price {
            display: none;
          }
        }
      }
    }
  }
  #overlay-background {
    background-color: $color-white;
    opacity: 0.7;
  }
  .product-fave-overlay-cbox {
    @media #{$small-only} {
      border: none;
    }
    border: 1px solid $color-dark-grey;
    .close-container {
      top: 30px;
      right: 30px;
    }
    .loyalty__popup {
      @media #{$small-only} {
        @include swap_direction(padding, 0);
        @include swap_direction(margin, 20px 0 0 0);
      }
      &.left {
        @media #{$small-only} {
          width: 100%;
          @include swap_direction(padding, 0);
          @include swap_direction(margin, 0);
        }
        width: 42%;
        float: #{$ldirection};
        text-align: center;
        img {
          width: 100%;
          @media #{$small-only} {
            position: absolute;
            z-index: -1;
            right: 0;
            width: 165px;
          }
        }
      }
      &.right {
        float: #{$rdirection};
        width: 55%;
        font-size: 18px;
        @include swap_direction(padding, 40px 0);
        @include swap_direction(margin, 0 0 0 15px);
        .loyalty_post_signin_terms {
          font-size: 12px;
          line-height: 12px;
        }
        @media #{$small-only} {
          width: 100%;
          font-size: 14px;
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 0);
        }
        h2 {
          font-size: 30px;
          font-weight: normal;
          @include swap_direction(margin, 0 0 10px 0);
          @media #{$small-only} {
            font-size: 30px;
            width: 180px;
          }
        }
        h3 {
          color: $color-black;
          font-weight: bold;
          font-size: 19px;
          font-family: $base-font-family-roman;
          @media #{$small-only} {
            color: $color-black;
            font-weight: bold;
            font-size: 22px;
            font-family: $base-font-family-roman;
            width: 180px;
            @include swap_direction(margin, 20px 0 20px 0);
          }
        }
        p {
          @media #{$small-only} {
            @include swap_direction(margin, 10px 0 0 0);
          }
        }
      }
      &.right_content {
        @media #{$small-only} {
          @include swap_direction(padding, 10px 10px);
          @include swap_direction(margin, 0 0 0 5px);
        }
        @include swap_direction(padding, 16px 16px);
        li {
          @media #{$small-only} {
            list-style-type: disc;
            @include swap_direction(padding, 2px 0);
          }
          list-style-type: disc;
          @include swap_direction(padding, 5px 0);
        }
      }
      &.button {
        @media #{$small-only} {
          @include swap_direction(margin, 0 0 10px 0);
          @include swap_direction(padding, 0);
          font-size: 18px;
        }
        font-size: 21px;
        @include swap_direction(padding, 5px 30px 5px 30px);
      }
      &.join_loyalty_btn {
        display: block;
        font-size: 11px;
        @include swap_direction(padding, 15px 0);
        @media #{$small-only} {
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 0);
          font-size: 14px;
        }
      }
      .terms_conditions {
        font-size: 11px;
      }
    }
  }
  // My Account Landing Tout
  .logged-in {
    .subheader {
      .program_name {
        font-size: 25px;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
    .twocol {
      width: 35%;
      .gnav-loyalty-join-block {
        display: inline-block;
        .text-wrapper {
          .header {
            font-weight: bold;
            margin-bottom: 8px;
            color: $color-black;
            font-size: 1.4em;
          }
          .text {
            font-size: 14px;
          }
          .button-wrapper {
            display: block;
            .button {
              @include swap_direction(padding, 5px 25px);
              @include swap_direction(margin, 25px 0 0 0);
              background-color: $color-black;
            }
          }
        }
        .image-wrapper {
          width: 50%;
          float: #{$rdirection};
          margin-top: 40px;
        }
        .loyalty-benefits {
          width: 96%;
          margin-#{$ldirection}: 1px;
          margin-bottom: 1px;
          .loyalty-benefit {
            padding: 5px 0;
            width: 100%;
            position: relative;
            @media #{$large-up} {
              padding: 10px 0;
            }
            &-image-wrapper,
            &-copy-wrapper {
              height: 56px;
              display: table-row;
            }
            &-image-wrapper {
              float: left;
              position: relative;
              width: 88px;
            }
            &-copy {
              display: table-cell;
              vertical-align: middle;
              font-weight: bold;
              font-size: 1.2em;
            }
            &-1 {
              .loyalty-benefit-copy-wrapper {
                color: $color-cl-purple-2;
              }
            }
            &-2 {
              .loyalty-benefit-copy-wrapper {
                color: $color-cl-orange-yellow;
              }
            }
            &-3 {
              .loyalty-benefit-copy-wrapper {
                color: $color-cl-light-blue;
              }
            }
            &-4 {
              .loyalty-benefit-image {
                width: 80px;
                margin: 0 4px;
              }
              .loyalty-benefit-copy-wrapper {
                color: $color-notice-pink;
              }
            }
          }
        }
      }
      #my-account-loyalty-progress-section .account-loyalty {
        width: auto;
      }
    }
    .fourcol {
      width: 63%;
    }
  }
  .account-loyalty {
    .section-content {
      .star-points-benifits {
        display: none;
      }
      .loyalty__panel__points__bg__slide-container {
        .loyalty__panel__points__bg__Bar-container {
          width: 95%;
        }
        .loyalty__panel__points__bg__Bar-container .arrow-down {
          &.level-1 {
            border-top-color: $color-loyalty-lvl1;
          }
          &.level-2 {
            border-top-color: $color-loyalty-lvl2;
          }
          &.level-3 {
            border-top-color: $color-loyalty-lvl3;
          }
        }
      }
      #top-tier-redeem-msg {
        display: none;
      }
    }
    .loyalty__panel__points__bg__slide1 {
      .loyalty__panel__points__bg__slide__smart {
        &.level-1 {
          background-color: $color-loyalty-lvl1;
        }
        &.level-2 {
          background-color: $color-loyalty-lvl2;
        }
        &.level-3 {
          background-color: $color-loyalty-lvl3;
        }
      }
    }
  }
  &#landing {
    .outer-wrap {
      @media #{$large-med-up} {
        max-width: 1175px;
      }
    }
  }
}
