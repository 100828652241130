/*-----Power Reviews------*/
.mpp-product-v1 {
  .reviews {
    padding: 0px 0px 3px 15%;
  }
}

#pr-review-display {
  .pr-rd-no-reviews {
    .pr-snippet-write-review-link {
      position: absolute;
      bottom: 10px;
      border: 1px solid $color-black;
      border-radius: 2px;
      width: 25%;
      font-size: 15px;
      padding: 12px 15px;
      line-height: 1;
      right: 40%;
    }
  }
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Да';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Нет';
              }
            }
          }
          .pr-rd-flag-review-container {
            width: auto;
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          width: 44%;
          &:before {
            content: 'Фильтр:';
            @include breakpoint($ab-small-down) {
              content: 'Фильтр:';
            }
          }
        }
        .pr-rd-main-header-search-sort {
          width: 56%;
        }
        .pr-rd-review-header-sorts {
          width: 60%;
        }
        .pr-rd-sort {
          background: url('/media/images/stars/down-arrow.png') no-repeat scroll 100% 5px transparent;
        }
        &-with-filters {
          .pr-rd-search-container {
            .pr-rd-search-reviews-input {
              .pr-rd-search-reviews-icon-button {
                right: -20px;
              }
            }
          }
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-sort-group {
          &:before {
            content: 'Сортировать по:';
          }
        }
      }
      .pr-rd-sort-group {
        &:before {
          content: 'Сортировать по:';
        }
      }
      .pr-multiselect {
        .pr-multiselect-button {
          padding: 8px 26px 8px 1px;
          margin: 5px 10px 5px 0;
        }
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .tag-group {
      .pr-label-control {
        white-space: initial;
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    .form-group {
      select {
        padding-right: 21px !important;
      }
    }
  }
}
