@if $password_strength {
  #gnav_signin {
    .password-field {
      &__info {
        width: 50%;
      }
    }
  }
  #signin {
    #gnav_signin {
      .password-field {
        &__info {
          width: 50%;
        }
      }
    }
  }
}
