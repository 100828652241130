$sale_badge_color: #ff4500;

.section-store-locator {
  .store-locator-body {
    .form_element.search_container {
      input.search {
        letter-spacing: 0em;
      }
    }
    .form_element.country_container {
      .sbHolder {
        width: 125px;
      }
    }
  }
}

.gnav-drop-down-v1 {
  height: 45px;
  .dd-content.active {
    width: auto;
    .stores-menu-v1 {
      h3 {
        width: 400px;
      }
    }
  }
}

.contact_us_wrap {
  .beauty_product {
    #where_to_buy_element,
    #product_availability_element,
    #product_concern_element {
      display: none;
    }
  }
  .online_order {
    li[id='contact_us.promotional_offer_element'] {
      display: none;
    }
  }
  .store_service {
    display: none;
  }
  #contact-form-wrap.personal-info-form-container {
    .second-column-wrap {
      .button_container {
        width: 50%;
        input {
          letter-spacing: 0;
          font-size: 16px;
        }
      }
      .form_element.registered_member_container {
        width: 50%;
        line-height: 21px;
      }
      .form_element {
        &.legal_checkbox_container {
          width: 100%;
          float: inherit;
          margin-top: 10px;
          .terms_conditions {
            display: flex;
            input[type='checkbox'] {
              margin-#{$rdirection}: 8px;
            }
          }
        }
        label {
          text-transform: none;
        }
      }
    }
    .first-column-wrap {
      .form_element {
        label {
          text-transform: none;
        }
      }
    }
  }
}

.section-careers {
  .wrapper {
    #top_wrapper {
      #header {
        .branding.column {
          .field-elc-nodeblock-content {
            .basic-responsive-v1 {
              display: none;
            }
          }
        }
      }
    }
  }
}

#search-wrapper {
  .details {
    a {
      h4 {
        font-size: 13px !important;
      }
    }
  }
}

.cl-mpp-product-layout-v1 {
  .mpp-grid {
    #mpp-product-grid {
      .btn-quickview {
        left: 25px !important;
      }
    }
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .offer-promo-v1 {
    .headline,
    .cta {
      white-space: normal;
    }
  }
  .section-even-better-franchise {
    .mpp-product-v1 {
      .product-subtitle {
        height: 135px;
      }
    }
  }
  .cl-mpp-product-layout-v1 {
    .mpp-grid {
      li.mpp-box {
        margin-bottom: 25px;
      }
    }
  }
  .field-content {
    #search-wrapper {
      .loading {
        top: 20px;
      }
    }
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .store-locator-v1 {
    .store-locator-body {
      .geo_container {
        a {
          width: 213px;
        }
      }
      .search_alternative_text {
        margin-top: 14px;
      }
      .search_container {
        input {
          margin-top: 10px;
          width: 200px;
        }
      }
      .country_container {
        margin-top: 10px;
      }
      .search_container {
        a {
          width: 110px;
          font-size: 14px;
        }
      }
    }
  }
  #footer {
    .footer-top-v1 {
      .tel {
        padding-left: 10px;
      }
      .signup-forms {
        width: 65%;
        form {
          label[for='PC_EMAIL_ADDRESS'] {
            width: 39%;
          }
        }
        .newsletter-signup {
          .form-submit.button {
            width: 15.5%;
          }
        }
      }
    }
  }
  .section-careers {
    .image-with-text-v1 {
      .info {
        .body {
          p {
            line-height: 1;
          }
        }
      }
    }
  }
  #search-wrapper {
    #results-container {
      #col-2 {
        .results-summary {
          width: 200px;
        }
      }
    }
  }
}

.mm_3col_1tout_v1 {
  .explore {
    width: 25%;
  }
  .col {
    padding-right: 10px;
  }
  .tout-product-v1 {
    .tout-info {
      .heading {
        white-space: pre-line;
      }
    }
  }
}

#footer {
  .social-links {
    width: 12%;
  }
  .signup-forms {
    width: 68%;
    .newsletter-signup {
      .form-submit.button {
        padding: 4px 3px 3px;
        width: 13.7%;
      }
    }
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .full-width-2-row-v1 {
    .top {
      width: 55%;
      top: 70px !important;
    }
  }
  #navigation {
    .menu-item-blocks {
      .mm_3col_1tout_v1 {
        .col {
          width: 20.5%;
        }
        .tout {
          display: block;
        }
      }
    }
  }
  .appt-book {
    .service-select {
      .service {
        .service-details {
          h3.service-title {
            font-size: 23px;
          }
        }
      }
    }
  }
}

#navigation {
  .nav-menu {
    ul.mm-menu {
      li:first-child {
        .title {
          padding-left: 10px;
        }
      }
      .title {
        padding: 0 6px;
        a {
          font-size: 14px;
        }
      }
    }
  }
  .navigation-right {
    .title {
      a {
        font-size: 14px;
      }
    }
  }
}

#main {
  .skin-quiz.blueocean {
    header {
      height: auto;
    }
  }
  &.single {
    .panel {
      .shipping-panel {
        .input_phone_1 {
          padding-#{$ldirection}: 63px;
        }
      }
    }
  }
}

.gnav-cart-v1 {
  .cart-confirm-wrapper {
    .cart-overlay-products {
      .prod-info {
        .prod-details {
          .qty-price {
            .qty {
              float: left;
              margin-right: 35px;
            }
            .price {
              float: right;
            }
          }
        }
      }
    }
  }
}

.supercat-header-v1.supercat-header-no-image {
  .subheading {
    width: 55%;
  }
}

#ff-content {
  .ff_panel {
    .dimensions {
      .accordion-content {
        h3 {
          margin-top: 55px;
        }
        .benefit_help_text {
          padding-right: 20px;
          background-size: 138px 130px;
        }
      }
      .btn_benefit_8187,
      .btn_benefit_8180 {
        word-wrap: break-word;
      }
    }
  }
  #product-results {
    .add-to-bag {
      .btn-add-to-bag {
        font-size: 12px;
        letter-spacing: 0.6px;
      }
    }
  }
}

.cs-page-section-formatter-v1 {
  .cs-page-section {
    .cs-page-section-row-v1 {
      .row {
        .section-details {
          h2.section-title {
            font-size: 25px;
          }
        }
      }
    }
  }
}

.wrapper {
  #ff-wrapper {
    #ff-content {
      .breadcrumbs_container {
        #breadcrumbs {
          a {
            font-size: 0.77em;
            padding: 0 6px;
          }
        }
        .breadcrumb_reminder {
          padding: 0 6px;
          font-size: 0.77em;
        }
      }
    }
  }
}

.lpPoweredBy {
  display: none;
}

.content {
  .header-nav-formatter-v1 {
    .shortcuts {
      .header-nav-row-v1 {
        .lpPoweredBy {
          display: none;
        }
        .lpEmtStarRating {
          display: none;
        }
        .lpStaticButtonTR {
          a {
            padding: 0 0;
          }
        }
      }
      li.header-nav-row-v1.live-chat a {
        border: none !important;
        background: none !important;
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
      }
      li.header-nav-row-v1.live-chat a img {
        border: none !important;
        background: none !important;
        margin-left: 5px;
        padding: 0;
        width: auto;
        height: 17px;
      }
    }
  }
}

.three-step-formatter-v1 {
  .three-step-formatter-header {
    .cols {
      padding: 0 0 0 4%;
      .col {
        width: auto;
        padding-right: 4%;
      }
    }
  }
}

.skin-type {
  ul.quickshop-type {
    li a {
      word-wrap: break-word;
      min-height: 92px !important;
      width: 106px;
    }
  }
}

.cs-page-section-row-v1 {
  .section-details {
    .section-link {
      a {
        text-transform: none;
      }
    }
  }
}

.my-account-consultations-v1 {
  .user_results {
    .meet-clinique-diagnostics-item-v1 {
      h3 {
        font-size: 21px !important;
      }
    }
  }
}

#top {
  #navigation {
    .mm_4col_v1 {
      .col {
        min-height: 245px;
        .body {
          min-height: 165px;
        }
      }
    }
  }
}

#main {
  .contact_us {
    .customer_email_note {
      &.error {
        color: #000;
      }
    }
    ul {
      &.category {
        li {
          min-height: 170px;
        }
      }
    }
  }
}

#navigation {
  .navigation-right {
    .mm-menu {
      .first.signin {
        span.title {
          width: 220px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
        }
      }
    }
    span.title {
      padding: 0px 2px;
    }
  }
}

.power-couples-formatter-v1 {
  .power-couples-formatter-rows {
    .row-products {
      .product {
        .cta-btn-processed {
          text-transform: none;
          font-size: 15px;
        }
      }
    }
  }
}

.wrapper {
  #header {
    .branding.column {
      width: 26%;
    }
    .top-right.column {
      .block-template-offer-promo-v1 {
        width: 34%;
        padding-right: 0px;
      }
    }
  }
}

.page-products {
  .mpp-grid {
    #mpp-product-grid {
      #quickshop-wrapper {
        #quickshop {
          div.col1 {
            margin-left: 17.5%;
            .col1 {
              margin-left: inherit;
            }
          }
        }
      }
    }
  }
}

#sticky-bar {
  .sticky_content {
    .sticky_prod_info {
      img {
        padding-top: 3px;
      }
    }
  }
  #sticky_bar_content {
    .mm-menu {
      li {
        padding: 0 4px;
      }
    }
    .three-step-sticky-header {
      .three-step_sticky_content {
        .title {
          width: 16%;
          br {
            display: none;
          }
          b {
            font-weight: bold;
          }
        }
      }
    }
    .power_couple_sticky_content {
      .title {
        width: 25%;
        margin-right: 4%;
      }
    }
    .sticky_content {
      .sticky_prod_select {
        .btn-add-to-bag {
          width: 135px;
          padding: 0px 10px;
        }
        .price {
          font-size: 16px;
        }
      }
    }
  }
}

#quickshop {
  .col1 {
    margin-left: 17.5%;
  }
}

.cl-mpp-product-layout-v1 {
  .mpp-filter {
    .mpp-filter-content {
      li.filter-item.group_2,
      li.filter-item.group_3,
      li.filter-item.group_4 {
        width: 94px;
      }
    }
  }
  .mpp-grid {
    .badge_126 {
      background: $sale_badge_color;
    }
    ul.view-small {
      li.mpp-box {
        .mpp-product-hover-view {
          width: 193px;
          .badge {
            top: 35px;
            left: 30px;
          }
        }
        .badge {
          font-size: 10px;
          padding: 5px;
          width: 68px;
          height: 68px;
          top: 25px;
          left: 20px;
        }
      }
    }
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #footer {
    .signup-forms {
      .newsletter-signup {
        form {
          .form-submit.button {
            width: 15%;
          }
          label {
            width: 40%;
          }
          .form-text {
            width: 36%;
          }
        }
      }
    }
  }
}

.mpp-product-group-v1 {
  .mpp-product-v1 {
    .product {
      margin-bottom: 10px;
      min-height: 530px;
      .product-subtitle {
        height: 92px;
      }
      .product-details {
        min-height: 530px;
      }
    }
  }
}

.what-people-are-saying-module-v2 {
  .manual-reviews {
    min-height: 235px;
  }
}

.product-content-hotlist-v1 {
  .hotlist-heading {
    .share {
      .share-button.pinterest,
      .share-button.twitter {
        display: none;
      }
    }
  }
}

.device-pc {
  .spp_page_wrap {
    .page-spp {
      .breadcrumbs li a {
        font-family: 'HelveticaNeueLTStd55Roman', Arial, Sans-Serif;
      }
      .abstract {
        b {
          font-weight: 600;
        }
      }
    }
    .module-spp-detail {
      .container {
        .col1 {
          .badge {
            font-size: 10px;
            padding: 5px;
          }
          .badge_126 {
            background: $sale_badge_color;
          }
        }
      }
    }
  }
}

#navigation {
  .outer-wrap {
    max-width: 1175px !important;
  }
}

.site-logo-responsive-v1 {
  .left_logo-link {
    float: left;
  }
}

.module-spp-detail {
  .options {
    .shipping-cost-widget {
      .shipping-cost {
        p:nth-of-type(2) {
          span:nth-child(1) {
            width: 140%;
            float: left;
            margin-bottom: 5px;
          }
        }
      }
    }
    a.shipping_calc {
      display: block;
      margin-left: -1px;
      text-decoration: underline;
    }
  }
  .icon-large {
    float: right;
    top: -36px;
    right: 230px;
    position: relative;
  }
}

#ship-calc-overlay {
  .country-container {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    text-transform: uppercase;
    input,
    select {
      width: 100%;
      float: left;
      text-transform: uppercase;
    }
  }
  .calculation-result {
    .shipping-type {
      font-weight: bold;
    }
  }
  .close-popup {
    padding: 10px 15px 7px 15px;
    margin-left: 15px;
    font-size: 17px;
    @if $cr19 == true {
      border: 1px solid $cr19-border-black;
      color: $cr19-text-black;
    } @else {
      border: 1px solid #5dba98;
      color: #5dba98;
    }
  }
  .btn-add-to-bag {
    padding: 0 7px;
  }
  .missing_region,
  .missing_city {
    color: $color-red !important;
  }
}

.shipping-cost-widget {
  margin: 0 0 -5px 0;
  .delivery_region {
    margin-top: -10px;
  }
  .shipping-price {
    margin-right: 5px;
  }
  .shipping-title {
    font-weight: bold;
    margin-bottom: 5px !important;
  }
  .country-container {
    display: block;
    margin-bottom: -5px;
  }
  .city_region {
    text-transform: uppercase;
  }
}

#top {
  #header {
    .outer-wrap {
      max-width: 1175px;
    }
  }
}
/* -----Live Engagement------ */
.lp_desktop {
  #lpChat {
    .lp_actions_bar_container {
      .lp_actions_bar {
        .lp_action_item {
          .lp_action_wrapper {
            height: 65%;
          }
        }
      }
    }
  }
}

.pc_spp_view {
  .module-spp-detail {
    .product-full__price {
      &-container {
        width: 125px;
        display: table-cell;
        &.product-full__price-has_size,
        .product-full__price-size-select-container & {
          min-width: 225px;
        }
      }
      &-description {
        display: table-cell;
        vertical-align: middle;
      }
      &-loyalty {
        display: table;
        margin: 10px 0;
        &.active {
          font-weight: bold;
        }
        &.tier3,
        &.tier2,
        &.tier1 {
          margin: 0;
        }
        &--link a {
          font-weight: normal;
        }
      }
      &-text {
        margin-right: 20px;
        font-size: inherit;
        display: block;
        margin: 0px;
        padding: 0px;
        border: none;
      }
    }
    .order_arrival {
      .add-to-bag {
        float: none;
        margin-top: 10px;
      }
    }
  }
}
/*
  EMEAFEATUR-2375 -- Across the board, we only want to see the OT loyalty prefix for
  users that aren't signed in. Effectively this just says "FROM ..." For logged in users
  we know the disc price though
*/
.elc-user-state-logged-in {
  .loyalty_price_prefix {
    display: none;
  }
}

#shipping_calculator {
  .calculation-result {
    clear: both;
  }
}

.device-pc .wrap.page-spp.how_to_use.spp-extra {
  padding-bottom: 20px;
  border-bottom: solid 1px #ccc;
}

.footer-top-v1 {
  .form-text {
    width: 37% !important;
  }
}

.appt-book {
  .service-select {
    .book-now-bar {
      max-width: 330px;
      .minutes {
        width: 45%;
      }
      .button {
        width: 55%;
      }
    }
    .service {
      .service-image {
        height: 178px;
        width: 260px;
      }
      .service-details {
        min-height: 160px;
        h3.service-title {
          font-size: 28px;
        }
        .lesson_description {
          p {
            font-size: 17px;
          }
        }
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .mobile-phone-fieldset {
          float: right;
          padding: 0;
          .appt-book-mobile-prefix {
            width: 20%;
            display: inline-block;
          }
          .appt-book-mobile {
            width: 80%;
          }
        }
        .registration__email-list,
        .registration__terms,
        .registration__sms-list {
          input {
            margin-right: 8px;
          }
          .registration__email-list-text {
            display: inline-block;
            width: 90%;
          }
        }
        .registration__terms.error {
          color: $color-notice-pink;
        }
      }
    }
  }
  .confirmation-container {
    .appt-date-cal {
      .add-cal-title,
      .add-cal-desc {
        display: none;
      }
    }
  }
  .appointment-container {
    .appointments-buttons {
      .location-direction-links {
        display: none;
      }
    }
    .your-lessons,
    .appt-date {
      p {
        display: inline-block;
      }
    }
  }
}

.appt-book-overlay.my-appointments-overlay {
  .overlay-content-container {
    .canceled {
      span p {
        display: inline-block;
        margin: 0;
      }
    }
  }
}

.appt-book-overlay.appt-book-datepicker-overlay {
  .overlay-content-container {
    top: 125px;
    .overlay-close {
      right: 6px;
    }
    .pika-lendar {
      .pika-button.pika-day {
        text-align: center;
      }
    }
  }
}

@media screen and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: portrait) {
  .appt-book {
    .service-select {
      .service {
        .service-details {
          h3.service-title {
            font-size: 23px;
          }
        }
      }
      .selection-bar {
        .selection-time {
          padding-left: 30px;
          background-position: 2px;
        }
      }
    }
    .appt-book-content-header {
      .book-now-bar {
        padding-top: 75px;
      }
    }
  }
}

#customer_service {
  .wrapper {
    .clearfix {
      overflow: visible;
    }
  }
}

body {
  &.section-foundation-finder {
    #foundation-finder-quiz {
      .foundation-finder {
        &_undertone_selection {
          label {
            display: inline-block;
            width: min-content;
          }
        }
        &_form-selection {
          width: 980px;
        }
      }
      .skin-goal-two {
        margin: -20px auto 0;
      }
    }
  }
  &.section-loyalty {
    .vertical-carousel-navigation-v1 .module {
      border: unset;
    }
    .full-width-2-row-v1 {
      padding-bottom: 0;
      background-size: cover;
      #loyalty-25-banner {
        text-align: center;
        h3 {
          color: $color-black;
        }
        .cta {
          a {
            margin-top: 25px;
            border: 1px solid $color-black;
          }
        }
      }
    }
  }
}

.responsive-container {
  #offer-code-panel {
    .content {
      p {
        display: none;
      }
    }
  }
}

h1,
h2,
h3 {
  font-weight: 300;
}
/* Ingredients SPP */
.ingredients_container {
  .ingredients__header {
    font-weight: bold;
  }
}

.view-large {
  .mpp-product,
  .mpp-product-hover-view {
    .product-info {
      .product_brief__price-loyalty {
        margin-left: 53px;
      }
    }
  }
}

.checkout {
  &.opc__signin {
    #footer {
      margin-top: auto;
    }
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .pr-btn-fileinput {
        span {
          width: auto;
        }
      }
      .pr-media_image-form-group,
      .pr-media_videourl-form-group {
        display: block;
      }
    }
  }
}
/* Phone mask */
#shipping-panel,
.address_form_container,
#address_form_container {
  @include phone-masking;
}

#address_book {
  .intl-tel-input {
    .flag-dropdown {
      .selected-flag {
        @include swap_direction(padding, 4px 16px 6px 6px);
        &:hover {
          background: none;
        }
      }
      .country-list {
        top: 29px;
        width: 100%;
      }
    }
  }
  .phone_1_container,
  .phone_2_container {
    width: 400px;
    input[type='text'] {
      width: 100%;
    }
  }
}

//CL Randomizer
.random-gift-picker-formatter {
  #game-error,
  #game-unavailable {
    .pop-up-box-inner {
      p {
        &.small {
          line-height: 10px;
        }
      }
    }
  }
}

.invisible {
  &.focusable {
    display: none;
  }
}
