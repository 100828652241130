$medium-range: (641px, 768px);
$large-range: (769px, 1024px);
$pp-ipad-only: '#{$screen} and (min-width:#{upper-bound($medium-range)}) and (max-width:#{upper-bound($large-range)})' !default;

#shipping {
  #delivery-options-group {
    .radio-wrapper {
      float: left;
      margin-bottom: 20px;
      margin-right: 10px;
      width: 40%;
      input {
        margin-right: 5px;
      }
      label {
        font-weight: bold;
        margin-bottom: 10px;
        margin-left: 5px;
      }
      .opt_a {
        margin-left: 10px;
      }
      .opt_desc {
        display: block;
        font-weight: normal;
        margin-left: 23px;
        width: 100%;
      }
    }
  }
  .shipping-address-info {
    margin-bottom: 20px;
    width: 100%;
  }
  .h_newsletter {
    margin-top: 20px;
  }
  .click-and-collect {
    border: none;
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
    .personal-details {
      .form_element {
        margin: 5px 15px 10px 0;
        position: relative;
        width: 300px;
        &.first-name,
        &.last-name,
        &.phone1,
        &.phone2,
        &.region,
        &.city {
          display: inline-block;
        }
        .required {
          display: block;
          float: left;
        }
        label {
          color: inherit;
          float: left;
          font-family: inherit;
          margin-right: 10px;
          margin-bottom: 5px;
          max-width: 90%;
          text-transform: inherit;
          width: 89%;
        }
        input,
        select {
          width: 100%;
        }
      }
    }
    .local-collection {
      display: inline-block;
      .show-collection-point {
        h5 {
          color: #000;
          font-size: 1.17em;
          margin-top: 10px;
          .local-collection-link {
            font-size: 0.83em;
            @if $cr19 == true {
              color: $cr19-text-black;
            } @else {
              color: #5dba98;
            }
          }
        }
      }
      .form_element {
        a {
          border: 0;
          color: white;
          display: inline-block;
          font-size: 0.9em;
          letter-spacing: 0;
          line-height: 1.5;
          margin: 10px 0 0;
          padding: 4px 10px 3px;
          text-align: center;
          text-transform: uppercase;
          zoom: 1;
          @if $cr19 == true {
            background-color: $cr19-bg-black;
          } @else {
            background-color: #5dba98;
          }
        }
        a:hover {
          background: #44a07e none repeat scroll 0 0;
          text-decoration: none;
        }
        b {
          font-weight: bold;
        }
      }
    }
  }
  #delivery-options {
    #delivery-instructions {
      margin-bottom: 15px;
    }
  }
}

.click_and_collect_map {
  .close-container {
    display: none;
  }
  .overlay-content {
    height: 100%;
    position: relative;
    width: 100%;
    .map-container {
      display: block;
      height: 100%;
      position: relative;
      overflow: hidden;
      .search-box-cancel {
        background: url(/media/export/cms/images/icon_close.png);
        background-repeat: no-repeat;
        background-position: 100%;
        border: 1px solid #bdbdbd;
        color: #000;
        cursor: pointer;
        font-weight: lighter;
        height: 29px;
        margin: 5px 10px;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 31px;
      }
      .address-map-location-panel {
        height: 532px;
        display: inline-block;
        left: 0 !important;
        margin: 58px 29px 0 18px;
        padding-right: 0;
        position: initial;
        overflow-x: hidden;
        top: 0;
        width: 297px;
        @media #{$pp-ipad-only} {
          display: block;
          float: left;
          width: 26%;
        }
        label {
          display: inline-block;
        }
        &:hover {
          left: 0 !important;
        }
        .close {
          display: none !important;
        }
        header,
        .messages {
          display: none;
        }
        .locations {
          background-color: #ffffff;
        }
        .location {
          border-bottom: 1px solid #bdbdbd;
          border-color: #666666;
          display: inline-table;
          height: 162px;
          margin: 0;
          padding-bottom: 16px;
          padding-top: 18px;
          &:first-child {
            padding-top: 22px;
          }
          input {
            position: relative;
            float: left;
          }
          .service_provider,
          .title,
          .address,
          .location_delivery_cost {
            margin-left: 20px;
          }
          .service_provider {
            cursor: pointer;
            span {
              color: #666666;
              font-size: 18px;
            }
          }
          .title,
          .address,
          .location_delivery_cost {
            color: #8f8f8f;
            font-size: 13px;
            line-height: 18px;
          }
          .title,
          .address {
            margin-bottom: 0;
          }
          .title {
            background-image: url('/media/export/cms/images/icon_location.png');
            background-repeat: no-repeat;
            span {
              padding-left: 15px;
            }
          }
          .address,
          .location_delivery_cost {
            padding-left: 15px;
            p {
              margin-top: 0;
            }
          }
          div.button {
            margin-left: 20px;
          }
          .button {
            .select-access-point {
              border: 0;
              color: #ffffff;
              cursor: pointer;
              font-size: 13px;
              height: auto;
              margin: 10px 0 0;
              padding: 5px 10px;
              text-align: center;
              text-decoration: none !important;
              width: auto;
              @if $cr19 == true {
                background-color: $cr19-bg-black;
              } @else {
                background-color: #5dba98;
              }
              &.disabled {
                cursor: default;
                @if $cr19 == true {
                  border: 1px solid $cr19-border-black;
                  color: $cr19-text-black;
                } @else {
                  border: 1px solid #5dba98;
                  color: #5dba98;
                }
              }
              &:hover {
                background: #44a07e none repeat scroll 0 0;
                text-decoration: none;
              }
            }
          }
        } // location
      }
      .map-wrapper {
        display: inline-block;
        height: 600px;
        margin-top: 15px;
        vertical-align: top;
        width: 800px;
        @media #{$pp-ipad-only} {
          width: 65%;
        }
        .select_postmat {
          color: #040a2b;
          font-size: 28px;
          margin-left: 100px;
          @media #{$pp-ipad-only} {
            margin-left: 35px;
          }
        }
        .address-map-options-search-panel {
          position: relative;
          top: 0;
          left: 0;
          margin: 15px auto 10px;
          width: 806px;
          @media #{$pp-ipad-only} {
            width: 100%;
          }
          .options-search-box-wrapper {
            .options-search-box {
              select {
                background-color: #fafafa;
                background: url(/media/export/cms/images/icon_metro.png);
                background-repeat: no-repeat;
                background-position: 100%;
                border: 1px solid #040a2b;
                color: #5a5a5a;
                cursor: pointer;
                height: 31px;
                line-height: initial;
                margin: 0 18px 0 0;
                max-width: 227px;
                padding: 7px 10px;
                -moz-appearance: none;
                -webkit-appearance: none;
                text-align: center;
                width: 100%;
                @media #{$pp-ipad-only} {
                  margin-right: 10px;
                  padding: 7px 45px 7px 10px;
                  width: 34%;
                }
                &:first-child {
                  background: url(/media/export/cms/images/icon_city.png);
                  background-repeat: no-repeat;
                  background-position: 100%;
                }
                &::-ms-expand {
                  display: none;
                }
              }
            }
          }
          .address-map-search-panel {
            display: inline-block;
            float: none;
            margin: 0 0 0 10px;
            max-width: 283px;
            position: initial;
            vertical-align: bottom;
            width: 100%;
            @media #{$pp-ipad-only} {
              margin-left: 0;
              width: 22%;
            }
            select {
              background-color: #fafafa;
              border: 1px solid #040a2b;
              height: auto;
              margin: 0 10px;
              max-width: 227px;
              padding: 7px 0;
              text-align: center;
              width: 100%;
              vertical-align: bottom;
            }
            .change-date-container {
              margin-top: 8px;
            }
          }
        } // end address-map-options-search-panel

        .maps {
          height: 483px;
          margin: 19px 18px 16px 0;
          position: relative;
          width: 806px;
          @media #{$pp-ipad-only} {
            height: 475px;
            width: 100%;
          }
          .map {
            margin-bottom: 16px;
            width: 100%;
            height: 100%;
            display: block;
            .map-window-info {
              .title {
                h3 {
                  color: #666666;
                  font-size: 18px;
                }
              }
              .address {
                color: #8f8f8f;
                font-size: 13px;
                line-height: 18px;
                p {
                  margin-top: 0;
                }
              }
            }
          }
          .maps-toggle-container {
            position: absolute;
            top: 2px;
            right: 0;
            z-index: 2;
            span {
              background-color: #ffffff;
              background-image: url(/media/export/cms/images/icon_metro_map.png);
              background-position: 17%;
              background-repeat: no-repeat;
              color: #040a2b;
              font-size: 14px;
              height: 34px;
              line-height: 30px;
              margin: 0 1px;
              padding: 9px 15px 9px 50px;
              text-transform: uppercase;
              &:first-child {
                color: #ffffff;
                background-color: #040a2b;
                background-image: url(/media/export/cms/images/icon_city_map.png);
                background-position: 20%;
                padding: 9px 20px 9px 46px;
              }
              &.location-toggle {
                display: none;
              }
            }
          }
          .metro-map {
            .popup_container {
              position: absolute;
              padding: 14px 14px 9px;
              background-color: #f1f1f1;
              border: 1px solid #5d5e5c;
              border-radius: 6px;
              min-width: 150px;
              min-height: 100px;
              z-index: 101;
              top: 50px;
              .location {
                .statuspoint {
                  border-radius: 5px;
                  border: 1px solid #000;
                  width: 9px;
                  height: 9px;
                  position: absolute;
                  right: 0px;
                  top: 28px;
                }
                .title {
                  h3 {
                    color: #666666;
                    font-size: 18px;
                    margin-bottom: 0;
                  }
                }
                .address {
                  color: #8f8f8f;
                  font-size: 15px;
                  line-height: 18px;
                  margin-bottom: 5px;
                  p {
                    margin-top: 0;
                  }
                }
                a.button {
                  margin-bottom: 5px;
                  margin-top: 5px;
                }
              }
              .close {
                position: absolute;
                right: 5px;
                top: 5px;
                text-decoration: none;
                font-size: 14px;
                font-weight: bold;
                color: #666;
                z-index: 102;
                span {
                  &:before {
                    content: 'X';
                  }
                }
              } // close
              .atop {
                position: absolute;
                top: -12px;
                left: 50%;
                margin-left: -12px;
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12px 12px 12px;
                border-color: transparent transparent #232323 transparent;
                &:after {
                  position: absolute;
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 11px 11px 11px;
                  border-color: transparent transparent #f1f1f1 transparent;
                  content: '';
                  top: 1px;
                  left: -11px;
                }
              } //atop
              .abot {
                position: absolute;
                bottom: -12px;
                left: 50%;
                margin-left: -12px;
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12px 12px 0 12px;
                border-color: #232323 transparent transparent transparent;
                &:after {
                  position: absolute;
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 11px 11px 0 11px;
                  border-color: #f1f1f1 transparent transparent transparent;
                  content: '';
                  top: -12px;
                  left: -11px;
                }
              } //abot
              .itemspopuplist_one {
                height: 225px;
                width: 275px;
                .location_wrapper {
                  width: 265px;
                }
              }
              .itemspopuplist_two {
                height: 225px;
                width: 555px;
                .location_wrapper {
                  margin-bottom: 5px;
                  width: 265px;
                  a.button {
                    bottom: 40px;
                    position: absolute;
                  }
                  div.button {
                    a {
                      bottom: 0;
                      position: absolute;
                    }
                  }
                }
              }
              .itemspopuplist_one,
              .itemspopuplist_two {
                .map-window-info {
                  .more-info {
                    padding: 5px 10px;
                    font-size: 15px;
                    &:hover {
                      background: #44a07e none repeat scroll 0 0;
                      text-decoration: none;
                    }
                  }
                  div.button {
                    a {
                      background-color: #fafafa;
                      cursor: pointer;
                      padding: 5px 10px;
                      font-size: 15px;
                      @if $cr19 == true {
                        border: 1px solid $cr19-border-black;
                        color: $cr19-text-black;
                      } @else {
                        border: 1px solid #5dba98;
                        color: #5dba98;
                      }
                    }
                  }
                }
              }
              .andmore {
                height: 225px;
              }
              .location_wrapper {
                position: relative;
                display: inline-block;
                height: 225px;
                margin-right: 10px;
                overflow: hidden;
                text-align: initial;
              }
              .mCustomScrollBox {
                position: absolute;
                overflow: hidden;
                height: 89%;
                max-width: 100%;
                outline: none;
                direction: ltr;
                left: 0px;
                overflow: auto;
                padding: 0px 0px 0 16px;
              }
            }
          }
          .map-title {
            display: none;
          }
        }
        //maps
        .metro-map {
          height: 100%;
          width: 100%;
          background-color: #fff;
        }
        #metromap {
          height: 100%;
          width: 100%;
          position: relative;
          > div {
            position: absolute;
            display: none;
            .points {
              > div {
                position: absolute;
              }
            }
          }
          .metroPoint {
            position: absolute;
            width: 20px;
            height: 20px;
            background: url(//pickpoint.ru/select/design/metro_marker2.png) center center no-repeat;
            z-index: 1;
          }
        }
        #metrozoom {
          z-index: 101;
          position: absolute;
          left: 30px;
          top: 50px;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          .metroPoint {
            position: absolute;
            width: 20px;
            height: 20px;
            background: url(//pickpoint.ru/select/design/metro_marker2.png) center center no-repeat;
            z-index: 1;
          }
        }
        .map {
          .location {
            input[name='LOCATION_GROUP'] {
              display: none;
            }
            .statuspoint {
              .status_opened {
                background-color: #71a751;
                border: 1px solid #71a751;
              }
            }
            a.button {
              margin: 10px auto 0;
              padding: 5px 10px;
            }
            .button {
              display: inline-block;
              font-size: 13px;
              margin: 0 auto 10px 20px;
              text-align: center;
              width: auto;
              &.more-info {
                &:hover {
                  background: #44a07e none repeat scroll 0 0;
                  text-decoration: none;
                }
              }
              .select-access-point {
                background-color: #fafafa;
                cursor: pointer;
                height: auto;
                margin: 10px 0 0;
                padding: 5px 10px;
                text-align: center;
                text-decoration: none !important;
                @if $cr19 == true {
                  border: 1px solid $cr19-border-black;
                  color: $cr19-text-black;
                } @else {
                  border: 1px solid #5dba98;
                  color: #5dba98;
                }
                &.disabled {
                  border: 1px solid #cccccc;
                  color: #cccccc;
                  cursor: default;
                }
              }
            }
          }
          .gm-style {
            .map-info-window {
              .location {
                input {
                  display: none !important;
                }
              }
            }
          }
        } //map
      } //map-wrapper

      .map-title {
        background-color: #040a2b;
        color: #fff;
        font-size: 16px;
        font-weight: normal;
        height: auto;
        margin: 0;
        padding: 7px;
        border-color: #666666;
        text-align: center;
        width: 281px;
        @media #{$pp-ipad-only} {
          width: 100%;
        }
      }
      &.map {
        .locations {
          .location-info-windows {
            display: block;
          }
        }
      }
      &.metro {
        .locations {
          .metro-list,
          .location-info-windows {
            display: none;
          }
        }
      }
      .locations {
        .metro-list {
          padding: 10px 20px 10px 10px;
          .metro-info {
            padding: 3px 0;
          }
          .metro-qty {
            float: right;
          }
        }
      }
      .map-window-more-info {
        background: #fff;
        border: 1px solid #dbdbdb;
        left: 60%;
        position: absolute;
        top: 48%;
        transform: translate(-60%, -48%);
        width: 40%;
        z-index: 202;
        .inner {
          position: relative;
          overflow: hidden;
          .close {
            cursor: pointer;
            font-size: 23px;
            font-style: normal;
            font-weight: normal;
            padding: 10px;
            position: absolute;
            right: 0;
            top: 0;
            span::before {
              color: #000;
              content: 'X';
              display: block;
              font-size: 12px;
              padding-top: 0;
              text-decoration: none;
            }
          }
        }
        .content {
          max-height: 595px;
          margin-top: 35px;
          padding: 0 20px 20px;
          overflow: auto;
          width: 100%;
          div {
            margin-bottom: 6px;
          }
          h3 {
            font-size: inherit;
          }
          .hint_green {
            background: #71a751;
            color: #ffffff;
          }
          .hint {
            border-radius: 5px;
            display: inline-block;
            margin-bottom: 10px;
            padding: 4px 14px;
          }
          .image {
            img {
              display: block;
              margin: 0 auto;
            }
          }
          p {
            margin-bottom: 0;
          }
        }
      }
      .submit {
        bottom: 1px;
        display: none;
        left: 50%;
        margin-top: 20px;
        position: absolute;
        width: auto;
        z-index: 1;
        button {
          display: none;
        }
      }
    } //map-container
    .customzoom {
      .minus {
        position: absolute;
        width: 24px;
        height: 23px;
        background: url(//pickpoint.ru/select/design/zoomminus.gif) center center no-repeat;
        cursor: pointer;
        top: 115px;
      }
      .plus {
        position: absolute;
        width: 24px;
        height: 22px;
        background: url(//pickpoint.ru/select/design/zoomplus.gif) center center no-repeat;
        cursor: pointer;
      }
      .drag {
        position: absolute;
        width: 10px;
        height: 22px;
        background: url(//pickpoint.ru/select/design/zoomdrag.gif) center center no-repeat;
        cursor: pointer;
        left: 7px;
        top: 25px;
        z-index: 2;
      }
      .line {
        position: absolute;
        background: silver;
        width: 1px;
        height: 75px;
        top: 35px;
        left: 12px;
        z-index: 1;
      }
    }
    .map-container {
      .address-map-search-panel {
        position: inherit;
        z-index: 1;
        margin: 0;
        .search-box-toggle {
          height: 20px !important;
        }
        .status-bar-wrapper {
          position: fixed;
          bottom: 20px;
          left: 50%;
          width: 30em;
          margin-left: -15em;
          z-index: 2;
          background-color: #000;
          .status-bar {
            .message {
              padding: 0.5em;
              border: none;
              box-shadow: 0px 1px 4px #333;
              &:first-child {
                border-radius: 0;
              }
              &:last-child {
                border-radius: 0;
              }
              .close {
                float: right;
                a {
                  text-decoration: none;
                  color: inherit;
                }
              }
              &.error {
                background: #fed2024;
                color: white;
              }
              &.warning {
                background: #f6e914;
                color: black;
              }
              &.success {
                background: white;
                border-color: black;
                color: black;
              }
              &.information {
                background: black;
                color: white;
              }
            }
          }
        }
        .search-box-field {
          border-bottom: 1px solid #040a2b;
          border-left: 1px solid #040a2b;
          border-top: 1px solid #040a2b;
          border-radius: 0;
          float: none;
          font-size: 12px;
          height: 26px;
          margin-right: 60px;
          padding-left: 10px;
          -webkit-appearance: none;
          -webkit-border-radius: 0px;
          width: 164px;
          @media #{$pp-ipad-only} {
            margin-right: 0;
            width: 100%;
          }
        }
        .search-box-submit {
          background-color: #f2f2f2;
          background-image: url(/media/export/cms/images/icon_search.png);
          background-repeat: no-repeat;
          background-position: 94%;
          border: 1px solid #040a2b;
          color: #040a2b;
          cursor: pointer;
          font-size: 14px;
          height: 32px;
          line-height: initial;
          padding: 10px 12px;
          position: absolute;
          right: -4px;
          text-align: center;
          text-transform: uppercase;
          top: 0;
          width: 119px;
          @media #{$pp-ipad-only} {
            background-position: 50%;
            right: -23%;
            width: 34px;
          }
          span {
            display: block;
            margin-top: -2px;
            @media #{$pp-ipad-only} {
              display: none;
            }
          }
        }
        .search-box {
          position: relative;
          width: initial;
        }
      }
    } // map-container
  }
  .status_green {
    background-color: #0d9344;
  }
  .status_red {
    background-color: #be1d2c;
  }
}

#map-foreground-node {
  background-color: #ffffff;
  border-color: #666666;
  left: 50%;
  height: 613px;
  margin-left: -483px;
  padding: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 1168px;
  max-width: 1168px;
  max-height: 613px;
  z-index: 9999;
}

#map-overlay-background {
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  display: block;
  background-color: #000;
  opacity: 0.7;
  height: initial !important;
  z-index: 0;
  position: fixed;
}

#review {
  #checkout_complete {
    h3 {
      margin-top: 15px;
    }
    .transaction-details {
      .transaction-item {
        margin-bottom: 20px;
        dt {
          margin-bottom: 10px;
        }
        dd {
          b {
            font-weight: bold;
          }
        }
      }
    }
  }
}

#order_detail {
  #content {
    .transaction-details {
      .transaction-item {
        dd {
          b {
            font-weight: bold;
          }
        }
      }
    }
  }
}
