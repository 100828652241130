.spc_enabled {
  .opc__signin {
    &.quick_wins {
      #main {
        &.single {
          .accepted-privacy-policy,
          .pc_email_promo_container,
          .social-login__email-opt-in,
          .fb-disclaimer-wrapper,
          .promotions-container {
            margin-top: 5px;
            padding-top: 0;
            float: #{$ldirection};
            input[type='checkbox'] {
              @include swap_direction(margin, 10px 5px 10px 0);
              float: #{$ldirection};
              padding: 0;
              height: initial;
              width: initial;
              margin-bottom: 0;
              display: none;
              cursor: pointer;
              &:checked ~ label:after {
                content: '';
                display: block;
                position: absolute;
                top: -1px;
                #{$ldirection}: 4px;
                width: 3px;
                height: 9px;
                border: solid $color-cl-green-2;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
              }
            }
            label {
              font-size: 12px;
              margin-top: 8px;
              display: inline-block;
              float: #{$ldirection};
              position: relative;
              cursor: pointer;
              &:before {
                content: '';
                -webkit-appearance: none;
                background-color: transparent;
                border: 1px solid $color-darker-grey;
                padding: 5px;
                display: inline-block;
                position: relative;
                vertical-align: middle;
                cursor: pointer;
                margin-#{$rdirection}: 12px;
                float: #{$ldirection};
              }
              &.error {
                color: $color-red;
              }
            }
            .label-content {
              display: inline-block;
              float: #{$ldirection};
              width: 90%;
            }
            .label {
              font-size: 12px;
              margin-top: 8px;
              display: inline-block;
              float: #{$ldirection};
            }
            .email_promotions {
              margin-top: 0px;
              margin-bottom: 0px;
            }
          }
          .social-login {
            .social-login {
              &__terms {
                width: 100%;
                float: #{$ldirection};
              }
              &-terms-wrapper {
                display: none;
              }
            }
            .quick-wins-fb-loginBtn {
              display: none;
            }
            .social-login__email-opt-in {
              padding-#{$ldirection}: 13px;
              &-label {
                padding-#{$ldirection}: 0px;
                padding-top: 0;
              }
            }
            .fb-disclaimer-wrapper {
              padding-#{$ldirection}: 13;
              padding-top: 0;
            }
          }
          #new-account {
            .promotions-container {
              .checkout__subtitle,
              #providing-email {
                display: none;
              }
              .pc_email_privacy_popup {
                margin-#{$ldirection}: 23px;
                float: #{$ldirection};
                font-size: 12px;
                display: none;
              }
              .pc_email_promo_container {
                margin: 0;
                padding: 0;
              }
              a {
                color: $color-black;
                text-decoration: underline;
              }
            }
          }
          #return-user {
            .btn-primary {
              margin-top: 43px;
            }
          }
          .quick-wins-hidden {
            display: none;
          }
        }
      }
    }
  }
  #offer-code-panel {
    #offer_code {
      .offer-code__submit {
        float: none;
        padding-#{$rdirection}: 0;
      }
    }
  }
}

#shipping-panel {
  .intl-tel-input {
    .flag-dropdown {
      .country-list {
        top: 35px;
        width: 272px;
      }
      .selected-flag {
        padding: 7px 4px 6px 6px;
      }
    }
  }
}
