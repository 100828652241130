#signin {
  .signin_container {
    #signin-block {
      #new-account,
      #return-user {
        width: 50%;
        display: inline-block;
      }
      .inner-block {
        .loyalty__block {
          display: inline-block;
          position: absolute;
          margin-left: 0;
          padding-left: 25px;
          .loyalty__subdivision {
            &__left_block {
              padding: 10px 0;
            }
            &__right_block {
              @media #{$medium-only} {
                right: -25%;
              }
            }
          }
        }
      }
      .divider {
        .social-login__divider {
          display: none;
        }
      }
    }
    .signin-social-section {
      width: 100%;
      .social-login {
        .social-login__divider {
          display: block;
        }
        .social-login__container {
          width: 50%;
        }
      }
    }
  }
}
